import React, { useState } from "react";

import LessonLayout from "~/layouts/lesson";
import TeacherViewWrapper from "~/layouts/teacher-view-wrapper";

import Button from "~/components/button";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import Note from '~/components/TextBox/Note';
import { StudentCard, TeacherCard } from "~/components/content-card";

const Page = () => {
  const [studentFullScreen, setStudentFullScreen] = useState<boolean>(false);
  const [teacherFullScreen, setTeacherFullScreen] = useState<boolean>(false);

  return (
      <LessonLayout
        current_step={8}
        lesson={"Lesson 3"}
        color={"ms"}
        total_count={8}
        module_title="Will You Buy What They Are Selling?"
      >
        <ContentWrapper>
          <H2 underline>Step 8</H2>

          <TeacherViewWrapper>
            <StudentCard
              fullScreen={studentFullScreen}
              hidden={teacherFullScreen}
              highSchool={false}
              setFullScreen={setStudentFullScreen}
            >
              <p>Now that you have added criteria in the "Lesson 3 Persuasion and Relevance" category of your rubric, look again at the CBD websites and use this category of your rubric to score those sites. (Remember, you have a separate copy of the rubric for each site.)</p>

              <div className="flex flex-wrap md:flex-nowrap justify-between">
                <Button
                  action="secondary"
                  className="mt-2 md:mt-0"
                  color="purple"
                  icon="external"
                  href="/article/cbd/cbd-now"
                  title="CBD website 1"
                >
                  CBD website 1
                </Button>
                <Button
                  action="secondary"
                  className="mt-2 md:mt-0 md:mx-2"
                  color="purple"
                  icon="external"
                  href="/article/cbd/cbd-truth"
                  title="CBD website 2"
                >
                  CBD website 2
                </Button>
                <Button
                  action="secondary"
                  className="mt-2 md:mt-0"
                  color="purple"
                  icon="external"
                  href="/article/cbd/cansativa-organics"
                  title="CBD website 3"
                >
                  CBD website 3
                </Button>
              </div>
            </StudentCard>
            <TeacherCard
              fullScreen={teacherFullScreen}
              hidden={studentFullScreen}
              highSchool={false}
              setFullScreen={setTeacherFullScreen}
            >
              <p>Students now use their rubric to score the CBD websites according to the persuasion and relevance criteria. This should only take a few minutes.</p>

              <p>If time is running short, you can have students skip this step and score this section when they score their rubrics in Lesson 4.</p>

              <Note color="ms" headerText="An Important Note about the Rubric">
                <p>The rubric is meant to be a dynamic document that students can adapt or modify as they continue to use it. The important thing is that students can justify why they would add something or delete something from the rubric. In some cases, students might place more value on some of the statements in each category of the rubric than others. This means that different students are likely to place more value on some statements than others thereby leading to different rubric scores for a given health resource. The important outcome is not the score, but rather the ability of each student to explain why he or she arrived at a specific score when evaluating information.</p>
              </Note>

              <div className="font-bold mt-5 text-yellow-800">Optional Homework Assignment</div>

              <p>Instruct students to use the internet to find websites that illustrate one or more of the persuasion strategies discussed in this lesson.</p>

              <div className="font-bold text-red-800">Preparation for Lesson 5</div>

              <p>In Lesson 5, students will use their rubrics to evaluate health-related information of their own choosing. They can find information on the internet, TV, social media, magazines, newspapers, or other sources. Depending on your schedule, this may be a good time for you to assign students the task of thinking of a health-related question based on their own interest and finding one or more resources (websites or other media pieces) to answer their question.</p>
            </TeacherCard>
          </TeacherViewWrapper>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
